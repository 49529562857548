@import "global/reset";
@import "global/fancybox";

@import "base/colors";
@import "base/fonts";
@import "base/common";

@import "base/slick";
@import "base/slick-images";
@import "base/slick-theme";
@import "base/footer-about";
@import "base/we-main";

@import "sections/header";
@import "sections/card-grid";
@import "sections/hero-banner";
@import "sections/cta";
@import "sections/404";

body{
    max-width: 1920px;
    margin: 0 auto;
    background-color: #F0F0F0;
}

.ua-safari{
    .hero-banner .content-wrapper .map-wrap svg{
        width: auto;
    }
}
.ua-ie-11{
    .hero-banner .content-wrapper .map-wrap svg{
        width: 410px;
    }
}
