.card-grid{
    width: 100%;
    position: relative;
    display: block;
    background-color: #000;
    min-height: 462px;
    padding: 55px 0 15px;
    @media screen and (max-width: 1024px) {
        padding: 30px 0;
    }
    .container{
       @media screen and (min-width: 1100px) {
        padding:0 30px;
       }
    }
    .row{
        width: 100%;
        margin: 0;
        position: relative;
      
        .card-content{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            width: 100%;
            overflow: hidden;
            .card-item{
                display: flex;
                flex-wrap: wrap;
                margin: 0 10px;
                max-width: 230px;
                width: calc(20% - 22px);
                margin: 0 11px 22px;
                justify-content: center;
                background-color: #fff;
                min-height: 360px;
                max-height: 360px;
                overflow: hidden;
                @media screen and (min-width: 992px) and (max-width: 1100px) {
                    width: calc(25% - 22px);
                    min-height: 340px;
                    max-height: 340px;
                }
                @media screen and (min-width: 768px) and (max-width: 991px) {
                    width: calc(33% - 30px);
                    margin: 15px;
                    min-height: 340px;
                    max-height: 340px;
                    max-width: 100%;
                }
                @media screen and (min-width: 596px) and (max-width: 767px) {
                    width: calc(50% - 30px);
                    margin: 15px;
                    max-width: 100%;
                    min-height: 320px;
                    max-height: 320px;
                }
                @media screen and (max-width: 595px) {
                    width: 100%;
                    max-width: 240px;
                    min-height: 320px;
                    max-height: 320px;
                    margin: 15px 0;
                    left: auto !important;
                }
                .img-wrap{
                    width: 300px;
                    max-height: 210px;
                    overflow: hidden;
                    border-bottom: 1px solid #000;
                    @media screen and (max-width: 1024px) {
                        max-height: 190px;
                        border-bottom: none;
                    }
                    @media screen and (max-width: 767px) {
                        padding-top: 0;
                        max-height: inherit;
                    }
                    img{
                       @media screen and (min-width: 1025px) {
                        height: 100%;
                       }
                        width: 100%;
                        @media screen and(max-width: 595px) {
                            height: auto;
                            margin: 0 auto;
                            width: 100%;
                            padding-top: 0;

                        }
                    }
                }
                .auther-text{
                    padding: 16px 12px;
                    text-align: center;
                    background: #fff;
                    width: 100%;
                    height: 150px;
                    transition: all 0.25s ease-in-out;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    @media screen and (max-width: 1024px) {
                        height: 160px;
                        border-top: 1px solid #000;
                    }
                    @media screen and (max-width: 595px) {
                        height: auto;
                    }
                    h5{
                        color: #000;
                        margin-bottom: 8px;
                        font-size: 18px;
                        font-weight: 600;
                        text-transform: uppercase;
                        @media screen and (max-width: 1024px) {
                            font-size: 17px;
                        }
                    }
                    p{
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0;
                        &:nth-child(3){
                            font-weight: 600;
                        }
                    }
                    .link{
                        opacity: 0;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                        text-decoration: none;
                        margin-top: 10px;
                        display: block;
                        @media screen and (max-width: 1024px) {
                            color: #000;
                            opacity: 1;
                        }
                    }
                    
                }
                &:hover{
                    @media screen and (min-width: 1025px) {
                        .auther-text{
                            // transform: translateY(-60px);
                            background-color: #f34336;
                            height: 170px;
                            h5,p{
                              color: #fff;
                            }
                            .link{
                                opacity: 1;
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .no-result{
        display: none;
    }
    &.no-result{
      .no-result{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 440px;
        h2{
            color: #fff;
        }
      }
      .card-content-grid{
        display: none;
      }
    }
}