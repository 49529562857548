footer{
	background-color: #e6e6e6;
	.footer-holder{
        padding-top: 20px;
        border-top: 1px solid #000;
		display: flex;
		p{
			font-size: 14px;
			color: #000;
			font-weight: 400;
		}
	}
}