header{
    z-index: 999;
    position: fixed;
    width: 100%;
    background: #fff;
    padding: 14px 0;
    max-width: 1920px;
    @media screen and (max-width: 1024px) {
        border-bottom: 1px solid #000;
        padding-bottom: 0;
    }
  .content-wrap{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000;
    padding-bottom: 22px;
    @media screen and (max-width: 1024px){
       justify-content: space-between;
       border-bottom: 0;
    }
     .logo-wrap{
        max-width: 200px;
        @media screen and (max-width: 992px) and(min-width: 768px) {
           max-width: 85px;
        }
        @media screen and (max-width: 767px){
            max-width: 100px;
        }
        @media screen and (max-width: 350px){
            max-width: 82px;
        }
     }
    .filter-wrap{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        @media screen and (max-width: 767px){
            background-color: #e6e6e6;
            max-width: 100%;
            justify-content: flex-start;
            position: absolute;
            right: 0;
            left: 0;
            width: 100%;
            top: 100px;
            display: none;
            height: calc(100vh - 97px);
            overflow: hidden;
            overflow-y: auto;
        }
        @media screen and (max-width: 350px){
            top: 89px;
        }
        &.active{
            .title-wrap{
                p{
                    &:after{
                        transform: translateY(-50%) rotate(224deg);
                    }
                }
            }
        }
        .title-wrap{
            padding-right: 25px;
            @media screen and (max-width: 992px) and(min-width: 768px) {
                padding-right: 10px;
            }
            @media screen and(max-width: 767px) {
                position: relative;
                width: 100%;
                display: none;
            }
            p{
                color: #000;
                text-transform: capitalize;
                margin: 0;
                font-weight: 400;
                font-size: 16px;
                @media screen and (max-width: 992px) and(min-width: 768px) {
                    font-size: 13px;
                }
                @media screen and(max-width: 767px) {
                    &:after{
                        content: '';
                        position: absolute;
                        transition: all .3s ease;
                        right: 0;
                        top: 50%;
                        width: 9px;
                        height: 9px;
                        border: 2px solid #000;
                        border-width: 0 2px 2px 0;
                        transform: translateY(-50%) rotate(45deg);
                    }
                }
            }
        }
        .filter-item{
            max-width: 320px;
            width: calc(22% - 20px);
            position: relative;
            margin: 0 10px;
            &.active{
                .arrow{
                    transform: rotate(180deg);
                    top: 18px;
                    right: 8px;
                    @media screen and (max-width: 992px) and(min-width: 768px) {
                        top: 14px;
                    }
                }
            }
            @media screen and (max-width: 1200px) {
                width: calc(22% - 10px);
                margin: 0 5px;
            }
            @media screen and (max-width: 992px) and(min-width: 768px) {
                width: calc(26% - 10px);
                margin: 0 10px 0 0;
            }
            @media screen and (max-width: 767px){
                // display: none;
                width: 100%;
                max-width: 100%;
                display: block !important;
                margin: 0;
            }
            p{
                color: #000;
                font-size: 16px;
                font-weight: 400;
                padding: 14px 25px 14px 14px;
                border-radius: 4px;
                background-color: #e6e6e6;
                position: relative;
                text-transform: capitalize;
                margin: 0;
                cursor: pointer;
                @media screen and (max-width: 992px) {
                    font-size: 14px;
                    padding: 10px 14px;
                }
                @media screen and (max-width: 767px) {
                    font-weight: 600;
                    padding: 18px 14px 10px;
                }
                &.active{
                    &::after{
                        transform: rotate(180deg);
                    }
                }
            }
            .arrow{
                display: inline-block;
                height: 15px;
                width: 15px;
                right: 15px;
                top: 14px;
                position: absolute;
                cursor: pointer;
                @media screen and (max-width: 992px) and(min-width: 768px) {
                    top: 10px;
                }
                @media screen and (max-width: 767px) {
                    display: none;
                }
                &:after{
                    content: '';
                    position: absolute;
                    transition: all .3s ease;
                    right: 0;
                    top: 50%;
                    width: 9px;
                    height: 9px;
                    border: 2px solid #000;
                    border-width: 0 2px 2px 0;
                    transform: translateY(-50%) rotate(45deg);
                }
            }
            ul{
                display: none;
                overflow: hidden;
                position: absolute;
                top: 48px;
                left: 0;
                width: 100%;
                z-index: 11;
                overflow: hidden;
                border-radius: 4px;
                overflow-y: auto;
                box-shadow: 0 6px 8px 0 hsla(0, 0%, 0%, 0.1);
                background-color: #e6e6e6;
                z-index: 11;
                padding: 0 10px 10px;
                max-height: 250px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                @media screen and (max-width: 992px) and(min-width: 768px) {
                    top: 37px;
                    padding: 0 6px 10px;
                }
                @media screen and (max-width: 767px) {
                    position: relative;
                    top: 0px;
                    box-shadow: none;
                    display: block !important;
                    height: 100% !important;
                    padding-bottom: 0;
                }
                li{
                    list-style: none;
                    padding: 8px 5px 0 0;
                    position: relative;
                    width: 100%;
                    display: inline-flex;
                    @media screen and (max-width: 767px) {
                        width: calc(50% - 10px);
                        padding: 8px 2px 0 0;
                    }
                    input{
                        display: block;
                        width: 15px;
                        margin-right: 10px;
                        color: #616161;
                        padding: 5px 12px 11px;
                        border-radius: 4px;
                        appearance: none;
                        @media screen and (max-width: 992px) and(min-width: 768px) {
                            margin-right: 4px;
                        }
                        &:checked{
                            &:before{
                                content: '';
                                position: absolute;
                                top: 15px;
                                left: 7px;
                                width: 12px;
                                height: 10px;
                                z-index: 2;
                                font-size: 17px;
                                color: #000;
                                background-image: url('/assets/images/checkmark.svg');
                                background-size: 100% 100%;
                                @media screen and (max-width: 992px) and(min-width: 768px) {
                                    top: 13px;
                                }
                                @media screen and (max-width:767px) {
                                    top: 13px;
                                }
                            }
                        }
                        &:after{
                            content:'';
                            position: absolute;
                            top: 11px;
                            left: 5px;
                            width: 17px;
                            height: 17px;
                            background: #fff;
                            border-radius: 2px;
                            @media screen and (max-width: 992px) and(min-width: 768px) {
                                width: 15px;
                                height: 15px;
                                top: 10px;
                            }
                            @media screen and (max-width:767px) {
                                top: 10px;
                            }
                        }
                        &.disabled{
                            ~label{
                                opacity: 0.5;
                                cursor: default;
                                color: #414042!important;
                            }
                        }
                    }
                    label{
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.36;
                        color: #141414;
                        cursor: pointer;
                        @media screen and (max-width: 1200px) {
                            font-size: 15px;
                        }
                        @media screen and (max-width: 992px) {
                            font-size: 12px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 12px;
                        }
                    }
                    &:hover{
                        label{
                            color: #f15725;
                        }
                    }
                }
            }
        }
    }
    .mobile-filter-wrap{
        display: none;
        background-color: #e6e6e6;
        max-width: 200px;
        width: 100%;
        padding: 10px 12px;
        border-radius: 4px;
        @media screen and (max-width: 767px) {
            display: block;
        }
        @media screen and (max-width: 350px) {
            max-width: 175px;
        }
        p{
            color: #000;
            text-transform: capitalize;
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                transition: all .3s ease;
                right: 0;
                top: 8px;
                width: 9px;
                height: 9px;
                border: 2px solid #000;
                border-width: 0 2px 2px 0;
                transform: translateY(-50%) rotate(45deg);
            }
        }
        &.active{
            p{
                &:after{
                    transform: translateY(-50%) rotate(222deg);
                    top: 12px;
                }
            }
        }
    }
    .clear{
        @media screen and (max-width: 767px){
            margin: 15px;
        }
        span{
            font-size: 16px;
            color: #000;
            cursor: pointer;
            @media screen and (max-width: 992px) and(min-width: 768px) {
                font-size: 13px;
            }
            @media screen and (max-width: 767px) {
                font-size: 12px;
            }
            
        }
    }
  }
}