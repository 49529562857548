.cta{
    background-color: #e6e6e6;
    padding: 30px 0;
    @media screen and (max-width: 992px) {
       padding: 30px 0;
    }
    .content-wrapper{
        .text-wrap{
            margin-bottom: 30px;
            @media screen and (max-width: 991px) {
                margin-bottom: 20px;
            }
            @media screen and (max-width: 767px) {
                text-align: center;
            }
            h2{
                font-weight: 300;
                @media screen and (min-width: 1200px){
                    font-size: 55px;
                }
            }
        }
        ul{
            display: flex;
            align-items: center;
            list-style: none;
            @media screen and (max-width: 767px) {
              display: block;
             }
            li{
                max-width: 225px;
                margin: 0 50px;
                @media screen and (max-width: 1100px) {
                    padding: 0;
                    margin: 0px 20px;
                }
                @media screen and (max-width: 767px) {
                    width: 100%;
                    max-width: 150px;
                    text-align: center;
                    margin: 30px auto !important;
                 }
                &:first-child{
                    max-width: 190px;
                    border-right: 1px solid #000;
                    margin: 0;
                    padding: 0 40px 0 0;
                    @media screen and (max-width: 1100px) {
                        padding: 0 20px 0 0;
                    }
                    @media screen and (max-width: 767px) {
                        max-width: 135px;
                        border-right: 0;
                        border-bottom: 1px solid #000;
                        padding: 0 0 20px;
                     }
                }
                &:last-child{
                    margin-right: 0;
                    padding-right: 0;
                    @media screen and (max-width: 767px) {
                        max-width: 172px;
                    }
                }
                &:nth-child(2){
                    @media screen and (max-width: 767px) {
                        max-width: 125px;
                    }
                }
                &:nth-child(3){
                    @media screen and (max-width: 767px) {
                        max-width: 127px;
                    }
                }
            }
        }
    }
}

