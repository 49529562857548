.error-content{
    padding: 80px 0px;
    background: #fff;
    display: flex;
    align-items: center;
    height: calc(100vh - 300px);
    box-sizing: border-box;
    min-height: 600px;
    @media screen and (max-width: 767px) {
        min-height: 400px;
        height: calc(100vh - 400px);
    }
    .content-wrap{
        text-align: center;
    h1{
        font-size: 300px;
        letter-spacing: -12px;
        font-weight: 700;
        width: 100%;
        max-width: 100%;
        @media screen and (min-width: 768px) and (max-width: 1201px) {
            font-size: 200px;
            letter-spacing: -8px;
        }
        @media screen and (max-width: 767px) {
            font-size: 100px;
            letter-spacing: -5px;
        }
    }
    h3{
        font-size: 40px;
        @media screen and (min-width: 768px) and (max-width: 1201px) {
            font-size: 35px;
        }
        @media screen and (max-width: 767px) {
            font-size: 20px;
        }
    }
    p{
        font-size: 30px;
        color: #818080;
        @media screen and (min-width: 768px) and (max-width: 1201px) {
            font-size: 25px;
        }
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    a{
        color: #fff;
        background-color: #f15725;
        padding: 18px 50px;
        border-radius: 35px;
        text-decoration: none;
        border: 2px solid transparent;
        &:hover{
            background-color: transparent;
            border: 2px solid #f15725;
            color: #f15725;
        }
        @media screen and (max-width: 767px) {
            padding: 12px 35px;
        }
    }
  }    
}