@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clear {
  clear: both;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

// devices viewport width starts at:
$widescreen: 1250px;
$max_width: 1200px;

.v-align {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.v-align-only {
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  @media screen and (max-width: 980px) {
    position: relative;
    top: auto;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
    width: auto;
    height: 100%;
  }

}


* {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "Inter", arial, sans-serif;
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}

body,
html {
  height: 100%;
  width: 100%;
  color: $darkGrey;
}
#we-main{
  padding-top: 80px;
}
a {
  cursor: pointer;
  color: $webeOrange;
}

img {
  display: block;
  border: 0 none;
  max-width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

.padding,
.container.padding {
  padding-top: 71px;
  padding-bottom: 85px;
  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 580px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

#wrapper {
  background: $white;
}


.light-grey-bg {
  background: $lightGrey;
}


.centered {
  text-align: center;
  .request-services {
    margin: 0 auto;
  }
}
.container {
  max-width: 1278px;
  padding: 0 40px;
  margin: 0 auto;
  @media screen and(max-width: 767px) {
    padding: 0 20px;
  }
  .small-container {
    padding-left: 0;
    padding-right: 0;
  }
}
.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.pulse-large {
    -webkit-animation-name: pulse-large;
    -o-animation-name: pulse-large;
    animation-name: pulse-large;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
}
.animated {
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes pulse-large {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  13% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    -ms-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  25% {
    -webkit-transform: scale3d(1.10, 1.10, 1.10);
    -ms-transform: scale3d(1.10, 1.10, 1.10);
    transform: scale3d(1.10, 1.10, 1.10);
  }

  37% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    -ms-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  50% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


@keyframes pulse-large {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  13% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3);
  }

  25% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25);
    -ms-transform: scale3d(1.25, 1.25, 1.25);
    transform: scale3d(1.25, 1.25, 1.25);
  }

  37% {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    -ms-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3);
  }

  50% {
    -webkit-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}