.hero-banner{
    position: sticky;
    width: 100%;
    top: 100px;
    z-index: 99;
    background: #fff;
    padding: 40px 0;
    overflow: hidden;
    @media screen and (min-width:992px) and (max-width: 1024px) {
        padding: 52px 0;
        position: relative;
        top: auto;
    }
    @media screen and (min-width:768px) and (max-width: 991px) {
        padding: 35px 0;
        position: relative;
        top: auto;
    }
    @media screen and (max-width: 767px) {
        padding: 50px 0 40px;
        position: relative;
        top: auto;
    }
   .text-block{
    max-width: 590px;
    @media screen and (min-width:1025px) and (max-width: 1199px) {
       max-width: 590px;
       width: calc(64% - 50px);
       margin: 0 25px 0 0;
    }
    @media screen and (min-width: 992px) and (max-width: 1024px) {
        max-width: 440px;
        width: calc(50% - 50px);
        margin: 0 25px 0 0;
        padding-bottom: 20px;
    }
    @media screen and (max-width: 991px) {
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding-bottom: 20px;
    }
    h1{
        font-weight: 300;
        b{
            font-weight: 600;
        }
    }
    .text-wrap{
        padding-top: 25px;
        @media screen and (max-width: 1024px) {
            padding-top: 15px;
        }
        p{
            font-size: 25px;
            color: #000;
            margin: 0;
            font-weight: 500;
            @media screen and (max-width: 1024px) {
                font-size: 20px;
                line-height: 1.1;
            }
            .single{
                display: none;
            }
            .multiple{
                display: inline-block;
            }
            &.single{
                span.multiple{
                    display: none;
                }
              span.single{
                display: inline-block;
              }
            }
        }
        #employee_text{
            color: #f19f22;
        }
        #agency_text{
            color: #5b51a2;
        }
        #states_text{
            color: #f15725;
        }
    }
    .btn-wrap{
        margin-top: 30px;
        @media screen and (max-width: 1024px) {
            margin-top: 15px;
            position: absolute;
            bottom: -15px;
        }
        @media screen and (max-width: 767px) {
            position: absolute;
            bottom: -15px;
        }
        .collapse_btn{
            color: #000;
            font-size: 16px;
            cursor: pointer;
            position: relative;
            &:before{
                content: "-";
                position: absolute;
                right: -12px;
                top: -4px;
                font-size: 22px;
            }
            &:after{
                content: "+";
                position: absolute;
                right: -17px;
                top: -5px; 
                font-size: 22px; 
                top: -4px;
                display: none;
            }
            .active{
                display: inline-block;
            }
            .inactive{
                display: none;
            }
        }
    }
   }
    .content-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @media screen and (max-width: 992px) {
           flex-wrap: wrap;
        }
        .text-wrap-right{
           opacity: 0;
           visibility: hidden;
           right: -1500px;
           position: absolute;
           @media screen and (max-width: 991px) {
             display: none;
           }
           p{
            font-size: 25px;
            color: #000;
            margin: 0;
            font-weight: 500;
            @media screen and (max-width: 767px) {
                font-size: 20px;
            }
            .single{
                display: none;
            }
            .multiple{
                display: inline-block;
            }
            &.single{
                span.multiple{
                    display: none;
                }
              .single{
                display: inline-block;
              }
            }
            }
            #employee_text_right{
                color: #f19f22;
            }
            #agency_text_right{
                color: #5b51a2;
            }
            #states_text_right{
                color: #f15725;
            }
            
        }
        &.active{
            .text-wrap-right{
                opacity: 1;
                visibility: visible;
                right: 0;
                top: 0;
                transition: all 0.75s linear;
            }
            .btn-wrap{
               .collapse_btn{
                &:before{
                    display: none;
                }
                &:after{
                  display: block;
                }
                .active{
                    display: none;
                }
                .inactive{
                    display: inline-block;
                }
               }
            }
        }
        .map-wrap{
            @media screen and (max-width: 1024px) and (min-width: 992px) {
              width: calc(50% - 20px);
              margin: 0px 10px;
            }
            @media screen and (max-width: 991px) {
                max-width: 100%;
                width: 100%;
                margin: 30px 0 0;
            }
            @media screen and (max-width: 595px) {
                margin: 0;
            }
            
            svg{
                width: 100%;
                height: 300px;
                @media screen and (max-width: 767px) {
                    height: 300px;
                    width: 100% !important;
                }
            }
        }
    }

    .filter-wrap{
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        display: none;
            @media screen and (max-width: 767px) {
                display: block;
            }
        .title-wrap{
            padding-right: 25px;
            @media screen and (max-width: 992px) and(min-width: 768px) {
                padding-right: 10px;
            }
            p{
                color: #000;
                text-transform: capitalize;
                margin: 0;
                font-weight: 400;
                font-size: 16px;
                @media screen and (max-width: 992px) and(min-width: 768px) {
                    font-size: 13px;
                }
            }
        }
        .filter-item{
            max-width: 300px;
            width: calc(20% - 20px);
            position: relative;
            margin: 0 10px;
            &.active{
                .arrow{
                    transform: rotate(180deg);
                    top: 18px;
                    right: 8px;
                    @media screen and (max-width: 992px) and(min-width: 768px) {
                        top: 14px;
                    }
                }
            }
            @media screen and (max-width: 992px) and(min-width: 768px) {
                width: calc(26% - 10px);
                margin: 0 10px 0 0;
            }

            p{
                color: #000;
                font-size: 16px;
                font-weight: 400;
                padding: 14px 25px 14px 14px;
                border-radius: 4px;
                background-color: #e6e6e6;
                position: relative;
                text-transform: capitalize;
                margin: 0;
                &.active{
                    &::after{
                        transform: rotate(180deg);
                    }
                }
                @media screen and (max-width: 992px) and(min-width: 768px) {
                    font-size: 14px;
                    padding: 10px 14px;
                }
            }
            .arrow{
                display: inline-block;
                height: 15px;
                width: 15px;
                right: 15px;
                top: 14px;
                position: absolute;
                @media screen and (max-width: 992px) and(min-width: 768px) {
                    top: 10px;
                }
                &:after{
                    content: '';
                    position: absolute;
                    transition: all .3s ease;
                    right: 0;
                    top: 50%;
                    width: 9px;
                    height: 9px;
                    border: 2px solid #000;
                    border-width: 0 2px 2px 0;
                    transform: translateY(-50%) rotate(45deg);
                }
            }
            ul{
                display: none;
                overflow: hidden;
                position: absolute;
                top: 48px;
                left: 0;
                width: 100%;
                z-index: 11;
                overflow: hidden;
                border-radius: 4px;
                overflow-y: scroll;
                box-shadow: 0 6px 8px 0 hsla(0, 0%, 0%, 0.1);
                background-color: #e6e6e6;
                z-index: 11;
                padding: 0 10px 10px;
                max-height: 225px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                @media screen and (max-width: 992px) and(min-width: 768px) {
                    top: 37px;
                    padding: 0 6px 10px;
                }
                li{
                    list-style: none;
                    padding: 8px 5px 0 0;
                    position: relative;
                    width: 100%;
                    display: inline-flex;
                    input{
                        display: block;
                        width: 15px;
                        margin-right: 10px;
                        color: #616161;
                        padding: 5px 12px 11px;
                        border-radius: 4px;
                        appearance: none;
                        @media screen and (max-width: 992px) and(min-width: 768px) {
                            margin-right: 4px;
                        }
                        &:checked{
                            &:before{
                                content: '\2713';
                                position: absolute;
                                top: 9px;
                                left: 7px;
                                width: 10px;
                                height: 10px;
                                z-index: 2;
                                font-size: 17px;
                                color: #000;
                            }
                        }
                        &:after{
                            content:'';
                            position: absolute;
                            top: 11px;
                            left: 5px;
                            width: 17px;
                            height: 17px;
                            background: #fff;
                            border-radius: 2px;
                            @media screen and (max-width: 992px) and(min-width: 768px) {
                                width: 15px;
                                height: 15px;
                            }
                        }
                        &.disabled{
                            ~label{
                                opacity: 0.5;
                                cursor: default;
                                color: #414042!important;
                            }
                        }
                    }
                    label{
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.36;
                        color: #414042;
                        cursor: pointer;
                        @media screen and (max-width: 992px) and(min-width: 768px) {
                            font-size: 13px;
                        }
                    }
                    &:hover{
                        label{
                            color: #f15725;
                        }
                    }
                }
            }
        }
    }
    .clear{
        span{
            font-size: 16px;
            color: #000;
            cursor: pointer;
            @media screen and (max-width: 992px) and(min-width: 768px) {
                font-size: 13px;
            }
        }
    }
    &#newyork{
        svg{
            #newyork{
                fill:#F15728 !important;
            }
        }
    }
    &#california{
        svg{
            #california{
                fill:#F15728 !important;
            }
        }
    }
    &#texas{
        svg{
            #texas{
                fill:#F15728 !important;
            }
        }
    }
    &#arizona{
        svg{
            #arizona{
                fill:#F15728 !important;
            }
        }
    }
    &#nevada{
        svg{
            #nevada{
                fill:#F15728 !important;
            }
        }
    }
    
    &#oregon{
        svg{
            #oregon{
                fill:#F15728 !important;
            }
        }
    }
    &#washington{
        svg{
            #washington{
                fill:#F15728 !important;
            }
        }
    }
    &#idaho{
        svg{
            #idaho{
                fill:#F15728 !important;
            }
        }
    }
    &#utah{
        svg{
            #utah{
                fill:#F15728 !important;
            }
        }
    }
    &#newmexico{
        svg{
            #newmexico{
                fill:#F15728 !important;
            }
        }
    }
    &#colorado{
        svg{
            #colorado{
                fill:#F15728 !important;
            }
        }
    }
    &#wyoming{
        svg{
            #wyoming{
                fill:#F15728 !important;
            }
        }
    }
    &#montana{
        svg{
            #montana{
                fill:#F15728 !important;
            }
        }
    }
    &#northdakota{
        svg{
            #northdakota{
                fill:#F15728 !important;
            }
        }
    }
    &#southdakota{
        svg{
            #southdakota{
                fill:#F15728 !important;
            }
        }
    }
    &#nebraska{
        svg{
            #nebraska{
                fill:#F15728 !important;
            }
        }
    }
    &#kansas{
        svg{
            #kansas{
                fill:#F15728 !important;
            }
        }
    }
    &#oklahoma{
        svg{
            #oklahoma{
                fill:#F15728 !important;
            }
        }
    }
    &#louisiana{
        svg{
            #louisiana{
                fill:#F15728 !important;
            }
        }
    }
    &#arkansas{
        svg{
            #arkansas{
                fill:#F15728 !important;
            }
        }
    }
    &#missouri{
        svg{
            #missouri{
                fill:#F15728 !important;
            }
        }
    }
    &#iowa{
        svg{
            #iowa{
                fill:#F15728 !important;
            }
        }
    }
    &#minnesota{
        svg{
            #minnesota{
                fill:#F15728 !important;
            }
        }
    }
    &#wisconsin{
        svg{
            #wisconsin{
                fill:#F15728 !important;
            }
        }
    }
    &#illinois{
        svg{
            #illinois{
                fill:#F15728 !important;
            }
        }
    }
    &#michigan{
        svg{
            #michigan{
                fill:#F15728 !important;
            }
        }
    }
    &#indiana{
        svg{
            #indiana{
                fill:#F15728 !important;
            }
        }
    }
    &#kentucky{
        svg{
            #kentucky{
                fill:#F15728 !important;
            }
        }
    }
    &#tennessee{
        svg{
            #tennessee{
                fill:#F15728 !important;
            }
        }
    }
    &#mississippi{
        svg{
            #mississippi{
                fill:#F15728 !important;
            }
        }
    }
    &#alabama{
        svg{
            #alabama{
                fill:#F15728 !important;
            }
        }
    }
    &#georgia{
        svg{
            #georgia{
                fill:#F15728 !important;
            }
        }
    }
    &#florida{
        svg{
            #florida{
                fill:#F15728 !important;
            }
        }
    }
    &#southcarolina{
        svg{
            #southcarolina{
                fill:#F15728 !important;
            }
        }
    }
    &#northcarolina{
        svg{
            #northcarolina{
                fill:#F15728 !important;
            }
        }
    }
    &#virginia{
        svg{
            #virginia{
                fill:#F15728 !important;
            }
        }
    }
    &#wverginia{
        svg{
            #wverginia{
                fill:#F15728 !important;
            }
        }
    }
    &#ohio{
        svg{
            #ohio{
                fill:#F15728 !important;
            }
        }
    }
    &#pennsylvania{
        svg{
            #pennsylvania{
                fill:#F15728 !important;
            }
        }
    }
    &#delaware{
        svg{
            #delaware{
                fill:#F15728 !important;
            }
        }
    }
    &#marryland{
        svg{
            #marryland{
                fill:#F15728 !important;
            }
        }
    }
    &#newjersey{
        svg{
            #newjersey{
                fill:#F15728 !important;
            }
        }
    }
    &#connecticut{
        svg{
            #connecticut{
                fill:#F15728 !important;
            }
        }
    }
    &#rhodeisland{
        svg{
            #rhodeisland{
                fill:#F15728 !important;
            }
        }
    }
    &#massachusetts{
        svg{
            #massachusetts{
                fill:#F15728 !important;
            }
        }
    }
    &#vermont{
        svg{
            #vermont{
                fill:#F15728 !important;
            }
        }
    }
    &#nhamisphere{
        svg{
            #nhamisphere{
                fill:#F15728 !important;
            }
        }
    }
    &#maine{
        svg{
            #maine{
                fill:#F15728 !important;
            }
        }
    }
    &#hawaii{
        svg{
            #hawaii{
                fill:#F15728 !important;
            }
        }
    }

    &.filter_active{
        svg{
            .state{
                fill: #E6E6E6 !important;
            }
        }
        &.newyork{
            svg{
                #newyork{
                   fill: #000 !important;
                }
            }
        }
        &.california{
            svg{
                #california{
                   fill: #000 !important;
                }
            }
        }
        &.texas{
            svg{
                #texas{
                   fill: #000 !important;
                }
            }
        }
        &.arizona{
            svg{
                #arizona{
                   fill: #000 !important;
                }
            }
        }
        &.nevada{
            svg{
                #nevada{
                   fill: #000 !important;
                }
            }
        }
        
        &.oregon{
            svg{
                #oregon{
                   fill: #000 !important;
                }
            }
        }
        &.washington{
            svg{
                #washington{
                   fill: #000 !important;
                }
            }
        }
        &.idaho{
            svg{
                #idaho{
                   fill: #000 !important;
                }
            }
        }
        &.utah{
            svg{
                #utah{
                   fill: #000 !important;
                }
            }
        }
        &.newmexico{
            svg{
                #newmexico{
                   fill: #000 !important;
                }
            }
        }
        &.colorado{
            svg{
                #colorado{
                   fill: #000 !important;
                }
            }
        }
        &.wyoming{
            svg{
                #wyoming{
                   fill: #000 !important;
                }
            }
        }
        &.montana{
            svg{
                #montana{
                   fill: #000 !important;
                }
            }
        }
        &.northdakota{
            svg{
                #northdakota{
                   fill: #000 !important;
                }
            }
        }
        &.southdakota{
            svg{
                #southdakota{
                   fill: #000 !important;
                }
            }
        }
        &.nebraska{
            svg{
                #nebraska{
                   fill: #000 !important;
                }
            }
        }
        &.kansas{
            svg{
                #kansas{
                   fill: #000 !important;
                }
            }
        }
        &.oklahoma{
            svg{
                #oklahoma{
                   fill: #000 !important;
                }
            }
        }
        &.louisiana{
            svg{
                #louisiana{
                   fill: #000 !important;
                }
            }
        }
        &.arkansas{
            svg{
                #arkansas{
                   fill: #000 !important;
                }
            }
        }
        &.missouri{
            svg{
                #missouri{
                   fill: #000 !important;
                }
            }
        }
        &.iowa{
            svg{
                #iowa{
                   fill: #000 !important;
                }
            }
        }
        &.minnesota{
            svg{
                #minnesota{
                   fill: #000 !important;
                }
            }
        }
        &.wisconsin{
            svg{
                #wisconsin{
                   fill: #000 !important;
                }
            }
        }
        &.illinois{
            svg{
                #illinois{
                   fill: #000 !important;
                }
            }
        }
        &.michigan{
            svg{
                #michigan{
                   fill: #000 !important;
                }
            }
        }
        &.indiana{
            svg{
                #indiana{
                   fill: #000 !important;
                }
            }
        }
        &.kentucky{
            svg{
                #kentucky{
                   fill: #000 !important;
                }
            }
        }
        &.tennessee{
            svg{
                #tennessee{
                   fill: #000 !important;
                }
            }
        }
        &.mississippi{
            svg{
                #mississippi{
                   fill: #000 !important;
                }
            }
        }
        &.alabama{
            svg{
                #alabama{
                   fill: #000 !important;
                }
            }
        }
        &.georgia{
            svg{
                #georgia{
                   fill: #000 !important;
                }
            }
        }
        &.florida{
            svg{
                #florida{
                   fill: #000 !important;
                }
            }
        }
        &.southcarolina{
            svg{
                #southcarolina{
                   fill: #000 !important;
                }
            }
        }
        &.northcarolina{
            svg{
                #northcarolina{
                   fill: #000 !important;
                }
            }
        }
        &.virginia{
            svg{
                #virginia{
                   fill: #000 !important;
                }
            }
        }
        &.wverginia{
            svg{
                #wverginia{
                   fill: #000 !important;
                }
            }
        }
        &.ohio{
            svg{
                #ohio{
                   fill: #000 !important;
                }
            }
        }
        &.pennsylvania{
            svg{
                #pennsylvania{
                   fill: #000 !important;
                }
            }
        }
        &.delaware{
            svg{
                #delaware{
                   fill: #000 !important;
                }
            }
        }
        &.marryland{
            svg{
                #marryland{
                   fill: #000 !important;
                }
            }
        }
        &.newjersey{
            svg{
                #newjersey{
                   fill: #000 !important;
                }
            }
        }
        &.connecticut{
            svg{
                #connecticut{
                   fill: #000 !important;
                }
            }
        }
        &.rhodeisland{
            svg{
                #rhodeisland{
                   fill: #000 !important;
                }
            }
        }
        &.massachusetts{
            svg{
                #massachusetts{
                   fill: #000 !important;
                }
            }
        }
        &.vermont{
            svg{
                #vermont{
                   fill: #000 !important;
                }
            }
        }
        &.nhamisphere{
            svg{
                #nhamisphere{
                   fill: #000 !important;
                }
            }
        }
        &.maine{
            svg{
                #maine{
                   fill: #000 !important;
                }
            }
        }
        &.hawaii{
            svg{
                #hawaii{
                   fill: #000 !important;
                }
            }
        }
    }
    
}