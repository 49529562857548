// colors
$white: #ffffff;
$darkGrey: #707071;
$lightGrey: #d6dede;
$lightestGrey: #f9fafa;
$webeBlack: #000;
$webeOrange: #ef9123;
$burntOrange: #f37736;
$webePurple: #a072a5;
$webeGreen: #a3cf5f;
$webeBlue: #62b4e5;
$webeRed: #e04444;
$pink: #e74769;
