h1,
h2,
h3,
h4,
h5 {
  color: #000;
  font-family: 'Inter', serif;
  font-weight: 400;
  -webkit-font-smoothing: initial;
}


h1{
  font-size: 55px;
  font-weight: 800; 
  line-height: 1;
  letter-spacing: -1.3px;
  @media screen and (max-width: 1199px) and (min-width: 1025px){
    font-size: 50px;
    max-width: 440px;
  }
  @media screen and (max-width: 1024px) and (min-width: 992px){
    font-size: 40px;
  }
  @media screen and (max-width: 991px) and (min-width: 768px){
    font-size: 36px;
  }
  @media screen and (max-width: 767px){
    font-size: 32px;
  }
}
h2 {
  font-size: 50px;
  letter-spacing: -1.3px;
  line-height: 1.2;
  font-weight: 700;
  @media screen and (max-width: 1199px) and (min-width: 1025px){
    font-size: 40px;
  }
  @media screen and (max-width: 1024px) and (min-width: 992px){
    font-size: 35px;
  }
  @media screen and (max-width: 991px) and (min-width: 768px){
    font-size: 32px;
  }
  @media screen and (max-width: 767px){
    font-size: 26px;
  }
}
h3 {
  font-size: 26px;
  letter-spacing: -1.3px;
  line-height: 1.2;
  margin: 0 0 31px;
  font-weight: 700;
  @media screen and (max-width: 767px) { margin: 0 0 20px; letter-spacing: -1px; }
  @media screen and (max-width: 500px) { font-size: 24px; }
}

h4 {
  font-size: 24px;
  letter-spacing: 0;
  @media screen and (max-width: 767px) { font-size: 22px; }
  @media screen and (max-width: 500px) { font-size: 20px; }
}

h5 {
  font-size: 20px;
  letter-spacing: 0;
  @media screen and (max-width: 767px) { font-size: 18px; }
  @media screen and (max-width: 500px) { font-size: 18px; }
}

b{
  font-weight: 600;
}
.full-width-content-darkgrey h2 {
  color: $white;
}

.purple-font {
  color: $webePurple;
  a:hover {
    text-decoration: underline;
  }
}

.orange-font {
  color: $webeOrange;
  a:hover {
    text-decoration: underline;
  }
}

.green-font {
  color: $webeGreen;
  a {
    color: $webeGreen !important;
    &:hover {
      text-decoration: underline;
    }
  }
}

.white-font {
  color: $white !important;

  a {
    text-decoration: none;
    color: $white !important;
    &:hover {
      text-decoration: underline;
    }
  }
}
a.white-font {
  color: $white !important;
  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 0 0 29px;
  font-size: 18px;
  line-height: 1.35;
  font-weight: 300;
  -webkit-font-smoothing: initial;

  @media screen and (max-width: 1023px) { font-size: 16px; }
  @media screen and (max-width: 767px) { font-size: 14px; margin: 0 0 20px;}
}
